<template>
  <div class="login" @keydown.enter="login">
    <nav-bar back-page="home">
      <template #left>
        <svg-icon svg-name="left-arrow" width="20px" height="20px"></svg-icon>
      </template>
      <!-- <template #center> <span class="login-title">{{navTitle}}</span> </template> -->
    </nav-bar>

    <page-scroll style="padding: 16px;">
      <div class="login-box">
        <h3 class="login-title">{{navTitle}}</h3>
        <p class="login-text">
          {{visitorDescription}}
          <!-- <a class="login-in theme-link-text-color" :href="url"> {{loginLinkText}} > </a> -->
        </p>

        <div class="login-form">
          <input type="text" class="login-input theme-input-color" v-model.trim="name" :placeholder="fullNamePlaceholder" maxlength="200" v-if="getName" />
          <p class="login-form-error" v-show="checkName">{{fullNameErrorText}}</p>


          <div v-if="lastNamePlaceholder">
            <input type="text" class="login-input theme-input-color" v-model.trim="lastName" :placeholder="lastNamePlaceholder" maxlength="200" v-if="getName" />
            <p class="login-form-error" v-show="checkLastName">{{fullNameErrorText}}</p>
          </div>

          <input type="text" style="margin-top:8px;" class="login-input theme-input-color" v-model.trim="email" :placeholder="emailPlaceholder" maxlength="300" v-if="getEmail" />
          <p class="login-form-error" v-show="checkEmail">{{emailErrorText}}</p>
          <input type="text" style="margin-top:8px;" class="login-input theme-input-color" v-model.trim="phone" :placeholder="phonePlaceholder" maxlength="300" v-if="getPhone" />
          <p class="login-form-error" v-show="checkPhone">{{phoneErrorText}}</p>
        </div>
      </div>
    </page-scroll>
    <!-- 开始聊天按钮 -->
    <div class="start-a-chat">
      <adaptive-button @btnClick="btnClick" btn-theme="blue" btnHeight="38px" fontSize="14px" :btn-text="btnText" click-methods="login" click-arg="Start a chat is click"></adaptive-button>
      <p class="button-desc" v-if="loginButtonDesc">
        {{ loginButtonDesc }}
      </p>
    </div>
    <loading v-if="loading" />
    <toast v-if="toastState" @changeState="setToastState" />
  </div>
</template>

<script>
import NavBar from 'components/contents/NavBar'
import AdaptiveButton from 'components/contents/AdaptiveButton'
import PageScroll from 'components/contents/PageScroll'
import Loading from 'components/common/Loading/Loading'
import Toast from 'components/common/Toast/Toast'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Login',
  components: {
    NavBar,
    AdaptiveButton,
    PageScroll,
    Loading,
    Toast
  },
  data () {
    return {
      url: '/account/login',
      name: '',
      lastName:'',
      email: '',
      phone: '',
      // 获取名称和email状态
      getName: false,
      getEmail: false,
      getPhone: false,
      checkName: false,
      checkLastName: false,
      checkEmail: false,
      checkPhone: false,
      loading: false,
      toastState: false,
      content: 'Please add your information below to get started!',
      loginText: 'Login',
      fullName: {
        placeholder: 'Name',
        errorText: 'Invalid name'
      },
      emailArea: {
        placeholder: 'Email',
        errorText: 'Invalid Email'
      },
      phoneArea: {
        placeholder: 'Phone',
        errorText: 'Invalid Phone'
      },
      title: 'Welcome',
      buttonText: 'Start chat'
    }
  },
  created () {
    // 如果已经登录则跳转聊天界面
    if (this.$store.state.login) {
      this.$store.commit('setPage', 'chat')
      return false
    }

    const shopSetting = this.$store.state.shopSetting
    // console.log('shopSettingshopSetting', shopSetting)

    this.getName = Boolean(Number(shopSetting.VisitorCollection[0].Name))
    this.getEmail = Boolean(Number(shopSetting.VisitorCollection[0].Email))
    this.getPhone = Boolean(Number(shopSetting.VisitorCollection[0].Phone))
  },
  methods: {
    ...mapMutations({
      setAutomationStatus: 'automation/setAutomationStatus'
    }),
    // back 按钮传回的数据 self 当前组件实例
    login (back) {
      this.$store.dispatch('setPage', 'chat')
      if (this.name && this.email) {
        this.$store.dispatch('setLogin', true)
      }
    },
    // 按钮点击事件
    /**
       * @param string methods 要调用的方法名称
       * @param arg 参数
       */
    btnClick (methods, arg) {
      this.setAutomationStatus({ login: true })
      // 调用方法 传入当前this指向 否则this是指向$options的
      // this.$options.methods[methods](arg, this);
      this[methods](arg)
    },
    // 设置toast气泡框状态
    setToastState (flag) {
      this.toastState = flag
    }
  },
  computed: {
    ...mapGetters({
      shopSetting: 'shopSetting'
    }),
    // 登录按钮描述
    loginButtonDesc () {
      return this.textSetting.visitorTips || ''
    },
    // iconWidth () {
    //   return this.$store.state.isMobile ? '10px' : '20px'
    // }
    visitorDescription () {
      return JSON.parse(JSON.parse(this.$store.state.shopSetting.liveChat[0])['text-settings'].visitor).description
    },
    navTitle () {
      // return this.$store.state.languageState ? this.$store.state.language.login[0] : this.title
      return JSON.parse(JSON.parse(this.$store.state.shopSetting.liveChat[0])['text-settings'].visitor).loginTitleText
    },
    contentText () {
      // return this.visitor.description || this.$store.state.languageState ? this.$store.state.language.login[1] : this.content
      return this.visitor.description || this.$store.state.languageState ? this.visitor.description : this.content
    },
    loginLinkText () {
      return this.$store.state.languageState ? this.$store.state.language.login[2] : this.loginText
    },
    fullNamePlaceholder () {
      // return this.visitor.name || this.$store.state.languageState ? this.$store.state.language.login[3] : this.fullName.placeholder
      return this.visitor.name || this.$store.state.languageState ? this.visitor.name : this.fullName.placeholder
    },
    lastNamePlaceholder () {
      // return this.visitor.name || this.$store.state.languageState ? this.$store.state.language.login[3] : this.fullName.placeholder
      return this.visitor.lastName
    },
    fullNameErrorText () {
      return this.$store.state.languageState ? this.$store.state.language.login[4] : this.fullName.errorText
    },
    emailPlaceholder () {
      // return this.visitor.email || this.$store.state.languageState ? this.$store.state.language.login[5] : this.emailArea.placeholder
      return this.visitor.email || this.$store.state.languageState ? this.visitor.email : this.emailArea.placeholder
    },
    emailErrorText () {
      return this.$store.state.languageState ? this.$store.state.language.login[6] : this.emailArea.errorText
    },
    /* +++ */
    phonePlaceholder () {
      return this.visitor.phone || this.$store.state.languageState ? this.visitor.phone : this.phoneArea.placeholder
    },
    phoneErrorText () {
      return this.phoneArea.errorText
    },
    btnText () {
      return this.visitor.startBtn || this.buttonText
    },
    textSetting () {
      return JSON.parse(this.$store.state.shopSetting.liveChat['0'])['text-settings']
    },
    visitor () {
      return JSON.parse(this.textSetting.visitor)
    }
  },
  watch: {
    '$store.state.shopSetting' (current) {
      const shopSetting = this.$store.state.shopSetting
      // console.log('settinging', shopSetting.VisitorCollection[0])
      this.getName = Boolean(Number(shopSetting.VisitorCollection[0].Name))
      this.getEmail = Boolean(Number(shopSetting.VisitorCollection[0].Email))
      this.getPhone = Boolean(Number(shopSetting.VisitorCollection[0].Phone))
      if (shopSetting.VisitorCollection[0].DataCollection === '0') {
        this.$store.dispatch('setPage', 'home')
      }
    }
  }
}
</script>

<style>
  .login {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .login-box {
    padding-top: 40px;
  }
  .button-desc {
    color: #9D9CAF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    margin-top: 8px;
    padding: 0 16px;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* 限制为 4 行 */
    -webkit-box-orient: vertical; /* 设置盒子方向为垂直 */
    overflow: hidden; /* 隐藏超出部分 */
    text-overflow: ellipsis; /* 显示省略号 */
  }
  .login-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #6C6B80;
    margin-top: 4px;
    text-align: center;
  }

  .login-in {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: #3b59fe;
    text-align: left;
    text-decoration: none;
  }

  .login-form {
    margin-top: -5px;
  }

  .login-form- {
    font-size: 14px;
    font-weight: 400;
    color: #e04949;
    text-align: left;
  }

  .login-input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #888;
    height: 42px;
    margin-top: 16px;
    /* 首行缩进 */
    text-indent: 12px;
    font-size: 14px;
  }

  .start-a-chat {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 16px 16px;
  }

  .login-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
</style>
